<template>
  <div v-if="showFeatures">
    <span class="ml-2" v-for="(feature, index) in features" :key="index">
      <feature-button
        v-bind="$attrs"
        v-if="isFeatureAvailable(feature.feature)"
        large
        :value="feature.feature"
        :about="true"
        class="mt-2 text-capitalize"
      ></feature-button>
    </span>
  </div>
  <div class="d-flex justify-center mt-3" v-else>
    <v-skeleton-loader
      class="ml-2"
      v-for="(item, index) in features"
      :key="index"
      type="button"
    ></v-skeleton-loader>
  </div>
</template>

<script>
import parcel from '@/mixins/parcel';
export default {
  mixins: [parcel],
  components: {
    FeatureButton: () => import('@/components/Core/Features/FeatureButton.vue')
  },
  computed: {
    showFeatures() {
      if (this.$store.getters.auth) {
        if (this.$store.getters.user.profile.features) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    },
    features() {
      return [...this.$store.getters.featuresList];
    }
  }
};
</script>

<style></style>
